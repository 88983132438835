import React from 'react'
import { Link } from 'gatsby'
import Carousel from "react-slick"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Button from 'components/CustomButtons/Button.js'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import style from 'assets/jss/styles/components/houseItemStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image';
import { CARGADOR } from 'constants/routes';

const useStyles = makeStyles(style)

export const ChargerItem = ({ data, preview }) => {
  const classes = useStyles();
  const { alt, text, description, slug, title } = data;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const images = data.images.map(img => {
    const image = img.childImageSharp.fixed;
    return (
      <div>
        <Img
          fixed={image}
          alt={alt}
          title={alt}
          className="slick-image"
        />            
        <div
          className={classes.coloredShadow}
          style={{
            backgroundImage: `url(${image})`,
            opacity: "1"
          }}
        />
      </div>
    )
  })

  const image = data.images[0].childImageSharp.fixed;

  if (preview) {  
    return (
      <Card className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5}>
            <CardHeader image plain className={classes.center}>         
              <Img
                fixed={image}
                alt={alt}
                title={alt}
                className="slick-image"
              />            
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: `url(${image})`,
                  opacity: "1"
                }}
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={7} md={7} className={classes.content}>
            <Info>
              <h6 className={classes.cardCategory}>{title}</h6>
            </Info>
            <p className={classes.description}>
              { description }
            </p>
            <Link to={CARGADOR(slug)}>
              <Button color={'info'} className={classes.navButton}>
                Ver más <ArrowForwardIcon />
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </Card>         
    )
  }

  return (
    <Card plain profile className={classes.card}>
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <CardHeader image plain className={classes.center}>         
            <Img
                fixed={image}
                alt={alt}
                title={alt}
                className="slick-image"
              />
          </CardHeader>
          <Info>
            <h6 className={classes.cardCategory}>{title}</h6>
          </Info>
          <p className={classes.description}>
            <div dangerouslySetInnerHTML={{__html: text.childMarkdownRemark.html}} />
          </p>
        </GridItem>
      </GridContainer>
    </Card>         
)}

export default ChargerItem
